@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.register_label {
    font-size: 14px !important;
    font-family: 'Inter';
    color: black !important;
}

.register-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .create_account {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    font-size: 12px;
  }
  
  input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    /* width: 100%; */
  }
  
  button:hover {
    opacity: 0.9;
  }
  
  .next-btn {
    background-color: #007bff;
    color: white;
    font-family: 'Inter';
    font-size: 15px;
  }
  
  .prev-btn {
    background-color: #6c757d;
    color: white;
  }
  
  .submit-btn {
    background-color: #28a745;
    color: white;
  }
  
  button.prev-btn, button.next-btn, button.submit-btn {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  
  h3 {
    margin-top: 20px;
    color: #333;
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .form-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  

  .required {
    color: red;
  }

  
.back-button {
  position: absolute;
  left: 10px; 
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #007bff;
  font-family: 'Inter';
}
/*  */
.close-btn {
  background-color: #f44336; 
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'Inter';
  font-size: 15px;
}

.close-btn:hover {
  background-color: #d32f2f; 
}

/*  */

.login-link {
  display: inline-flex;
  align-items: center;
  gap: 5px; 
}


.button_form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-group {
  display: flex;
  gap: 10px; 
  margin-top: 10px; 
}

/* Button styling */

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}


.close-btn, .next-btn {
  padding: 10px 20px; 
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 15px; 
  display: inline-block;
  text-align: center;
  height: 45px; 
  width: 120px; 
  box-sizing: border-box; 
}


.close-btn {
  background-color: #f44336;
  color: white;
}


.next-btn {
  /* background-color: #4CAF50;  */
  color: white;
}


.close-btn:hover, .next-btn:hover {
  opacity: 0.8;
}


.login-link {
  color: #007bff;
  text-decoration: none;
}

.login-link:hover {
  text-decoration: underline;
}

/*  */
.vendor_register {
  font-family: 'Inter';
  font-size: 18px !important;
}
.register_span {
  font-family: 'Inter';
  font-size: 14px !important;
}

/*  */

.form-buttons {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  gap: 10px; 
}

.prev-btn, .submit-btn {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Inter';
}

.prev-btn {
  background-color: #f44336; 
  color: white;
  border-radius: 4px;
  border: none;
}

.submit-btn {
  background-color: #4CAF50; 
  color: white;
  border-radius: 4px;
  border: none;
}


.vendor-modal .form-group label {
  font-weight: 600;
  margin-bottom: 5px; 
  text-align: left; 
  font-family: 'Inter';
  font-size: 16px;
  text-transform: none;
}

.vendor-modal .form-group input {
  width: 100%; 

  padding: 5px;
 
  margin-top: 0px;
  font-family: 'Inter';
  font-size: 14px;
}

.modal_popup {
  background: rgba(0, 0, 0, 0.65);
  /* overflow-y: auto; */
  /* max-height: 700px; */
}

.modal_input_usernname {
  width: 100%; 

  padding: 5px !important;
 
  margin-top: 0px;
  font-family: 'Inter';
  font-size: 14px;
}

/*  */

.form-group::placeholder {
  font-size: 14px !important;
}
