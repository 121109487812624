@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');



.store-icons {
    display: flex; 
    gap: 10px; 
}


.store-icon {
    width: 150px !important; 
    /* height: 50px;  */
}

.copyright_text {
    font-size: 12px !important;
    font-family: 'Inter';
}


/*  */

.footer_text {
    font-size: 16px !important;
    font-family: 'Inter';
}


.footer-about-us {
    font-size: 16px;
    font-family: 'Inter';
}

.main-footer {
    padding-top: 90px;
    padding-bottom: 20px;
}