@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


.login_text {
 font-family: 'Inter';
 font-size: 14px;
}

.signin_text {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 700;
}

.signin_para {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
}

.vendor_signin {
    width: 40%;
}