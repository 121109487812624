@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html, body {
    overflow-x: hidden;
    margin: 0;
    padding: 0; 
}

input[type="text"]{padding: 13px;}

.servicetext {
    font-family: 'Inter';
    font-size: 14px;
}
.ac_doorstep {
    font-family: 'Inter';
    font-size: 36px;
    font-weight: 800;
    color: #FFFFFF;;
    padding-top: 100px;
}

.store_icon_img {
    padding-top: 40px;
}

.homepage_acimg {
    max-width: 100%;  
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

.home_doorstep {
    background-color: #0F172A;
    margin-bottom: 50px;
}

.home_page_heading {
    /* font-family: 'Montserrat'; */
    font-family: 'Inter';
    font-size: 48px;
    color: #000000;
    font-weight: 700;
    margin-top: 75px;
}

.home_page_para {
    font-family: 'Inter';
    font-size: 10px;
    color: #ffffff;
    font-weight: 500;
}

.home_page_subparagraph {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 22px;
    color:#7A7A7A;
    /* text-align: center; */
    justify-content: center;
}

.bg_color {
    background-color: #0F172A;
}

.home_page_subpara {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
}

.line {
    width: 10%;        
    height: 6px;        
    background-color:  #1B2336;  
    margin-top: 10px;   
    margin-bottom: 20px; 
}

.header_line {
    width: 100%;
    height: 2px;
    background-color:  #D3D3D3;
}

.home_doorsteo_text {
    
    font-family: 'Inter';
    font-size: 22px;
    color: #FFFFFF;
    font-weight: 400;
    align-items: center;

}

.home_service_bg_img {
    background-image: url('../asserts/homeService_doorstep.png');
    background-size: cover;       
    background-position: center;   
    background-repeat: no-repeat;   
    width: 100%;
    height: 640px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 50px 0;
}


/* Medium screens (≥768px and <992px) */
@media (max-width: 991px) {
    .home_service_bg_img {
        height: 500px;
        padding: 40px 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .ac_doorstep {
        font-size: 30px;
    }

    .home_doorsteo_text {
        font-size: 14px;
    }

    .store_icon_img {
        width: 80%;
        max-width: 100px;
    }
}


@media (max-width: 767px) {
    .home_service_bg_img {
        height: 400px;
        padding: 30px 0;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .ac_doorstep {
        font-size: 24px;
    }

    .home_doorsteo_text {
        font-size: 12px;
    }

    .store_icon_img {
        width: 60%;
        max-width: 80px;
    }
}


@media (max-width: 575px) {
    .home_service_bg_img {
        height: 300px;
        padding: 20px 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .ac_doorstep {
        font-size: 20px;
    }

    .home_doorsteo_text {
        font-size: 10px;
    }

    .store_icon_img {
        width: 50%;
        max-width: 60px;
    }
}


/* FAQ */

.accordion-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: 'Inter';
    font-size: 14px;
    
}

.accordion-header .accordion-button {
    background-color: #0F172A !important;
    color: white !important; 
}

.accordion-header .accordion-button:not(.collapsed) {
    background-color: #0F172A !important; 
}


.accordion-body p {
    background-color: #6A789B;
    color: white; 
    font-family: 'Inter';
    font-size: 14px;
    text-align: start;
}



.accordion-button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: initial;
}

.accordion-item {
    width: 100%; 
}

.home_page_faq {
    text-align: center;
    align-items: center;
}

.faq_faq {
    padding-top: 50px;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 700;
    color: #0F172A;
}

.faq_text_heading {
    font-family: 'Inter';
    font-size: 26px;
    font-weight: 700;
    color: #000000;
}

.faq_text {
    font-family: 'Inter';
    font-size: 16;
    font-weight: 400;
    color:  #7A7A7A;
    padding-bottom: 30px;

}

/* .faq_image {
    max-width: 100%; 
    height: auto;     
    display: block;  
    margin: 0 auto;   
} */
.faq_image {
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media (max-width: 768px) {
    .faq_image {
        max-width: 80%; 
        margin: 0 auto;
    }
}

@media (max-width: 576px) {
    .faq_image {
        max-width: 90%; 
    }
}


/* conatctus */




/* SUBSCRIBE */
.subscribe_container {
    background-color: #3B82F6;
    padding-left: 80px;
    padding-top: 50px;
    padding-bottom: 20px;
}
.subscribe_para {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 40px;
    color: #FFFFFF;
    font-family: 'Inter';
    padding: 0;
    margin: 0;
}

.subscribe_input, .subscribe_button {
    height: 100%; 
    font-size: 16px; 
  }
  
  .subscribe_button {
    width: 50%; 
    background-color: #0F172A;
    color: #FFFFFF;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;
  }

  .subscribe_container {
    margin-bottom: -45px;
    position: relative;
    z-index: 3;
  }
  

@media (min-width: 992px) {
    .subscribe_container {
        padding-left: 80px;
        padding-top: 50px;
        padding-bottom: 20px;
    }

    .subscribe_para {
        font-size: 40px;
    }

    .subscribe_input, .subscribe_button {
        height: 100%;
        font-size: 16px;
    }

    .subscribe_button {
        width: 50%;
        background-color: #0F172A;
        color: #FFFFFF;
        font-family: 'Inter';
        font-size: 18px;
        font-weight: 600;
    }

    .subscribe_container {
        margin-bottom: -85px;
        position: relative;
        z-index: 3;
    }
}


@media (max-width: 991px) {
    .subscribe_container {
        padding-left: 40px;
        padding-right: 40px;
    }

    .subscribe_para {
        font-size: 30px;
    }

    .subscribe_input, .subscribe_button {
        height: 50px; 
    }

    .subscribe_button {
        width: 100%;
        font-size: 16px;
    }
}


@media (max-width: 767px) {
    .subscribe_container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        padding-bottom: 15px;
    }

    .subscribe_para {
        font-size: 25px;
    }

    .subscribe_input, .subscribe_button {
        height: 45px;
        font-size: 14px;
    }

    .subscribe_button {
        width: 100%;
        font-size: 16px;
    }
}


@media (max-width: 575px) {
    .subscribe_container {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .subscribe_para {
        font-size: 20px;
    }

    .subscribe_input, .subscribe_button {
        height: 40px; 
        font-size: 12px;
    }

    .subscribe_button {
        width: 100%;
        font-size: 14px;
    }
}

/*  */

/* Contact us */

.contactus_homepage {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.left-column,
.right-column {
    padding-left: 15px;
    padding-right: 15px;
}


.form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
}

.form-group {
    flex: 0 0 48%;
}

.homepage_contactus {
    padding-top: 50px;
    font-family: 'Inter';
    font-size: 26px;
    font-weight: 700;
    color: #1B2336;
}

.form-control {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
}

.contactus_homepage {
    margin-bottom: 50px;  margin-top: 100px;
    background-color: #E1E4E9;
}

.submit-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.contactus_submit_button {
    padding: 12px 25px;
    background-color: #0F172A;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Inter';
    font-family: 14px;
}

.contactus_submit_button:hover {
    background-color: #0056b3;
}


.contact-detail {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 15px;
}


.homepage_contact_input::placeholder {
    font-family: 'Inter';
    font-size: 14px; 
}

.icon-container img {
    width: 30px;
    height: 30px;
    /* margin-right: 15px; */
}


.text-container p {
    margin: 0;
    /* line-height: 0.4; */
    /* align-items: center !important; */
    text-align: left !important;
    justify-content: center;
    color: #ffffff;
    font-family: 'Inter';
}

@media (max-width: 765px)  {
    .text-container p {
        margin: 0;
        line-height: 1.4;
        align-items: center !important;
        text-align: center !important;
        justify-content: center;
        color: #ffffff;
        font-family: 'Inter';
    }
}

.text-container p:first-of-type {
    font-weight: bold;
}

.homepage_contactus_secondcolumn {
    background-color: #0F172A;
    padding-top: 90px;
}

@media (max-width: 767px) {
    .left-column, .right-column {
        flex: 0 0 100%; 
    }
    .form-group {
        flex: 0 0 100%; 
    }
    .contact-detail {
        flex-direction: column;
    }
}

@media (max-width: 991px) {
    .left-column, .right-column {
        flex: 0 0 48%; 
    }
}

@media (min-width: 992px) {
    .left-column, .right-column {
        flex: 0 0 48%;
    }
}

/* FAQ */



/* subscribe */

.subscribe_input {
    border-radius: 0; 
    width: 100%;
    height: 100%; 
}

.subscribe_button {
    border-radius: 2px; 
    width: 50%;
    height: 100%; 
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.row {
    display: flex;
    align-items: center; 
}




/* Banner */

.add-banner {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    gap: 20px;
}

.bannercard {
    background-color: #0F172A;
    color: white;
    padding: 20px;
    border-radius: 0px;
    display: flex;
    flex-direction: column; 
    justify-content: flex-start; 
    align-items: center; 
    box-sizing: border-box;
    width: 100%; 
    max-width: 350px; 
    height: 300px;
    text-align: center; 
}

.card-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center; 
    padding-bottom: 10px; 
}

.card-img {
    width: 120px;
    height: auto;
    object-fit: contain;
    margin-top: 15px;
}

.home_page_subpara {
    font-size: 18px;
    font-weight: bold;
}

.home_page_para {
    font-size: 14px;
}


/*  */

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
    font-family: 'Inter';
}

.subscribe_input {
    font-family: 'Inter', sans-serif;
}

.subscribe_input::placeholder {
    font-family: 'Inter', sans-serif;  
}
.invalid-feedback {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
    font-family: 'Inter';
}


/*  */


 
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .social-icons a {
    color: white;
    font-size: 14px;
    text-decoration: none;
  }
  
  .social-icons a:hover {
    color: #ccc; 
  }
  


  @media (max-width: 768px) {
    .lazyload {
        max-width: 100%;
        max-height: 100%;
    }
}

/*  */

.subscribe_para
{
  line-height: 48px;
}

@media (min-width: 992px) {
    .subscribe_container {
        padding-bottom: 30px;
        padding-left: 80px;
        padding-top: 30px;
    }

    }

.p-0 {
    padding: 0px;
}
.subscribe_container .error-message {
    background: #fff;
    border-radius: 4px;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    padding: 17px;
}
.subscribe_container .subscribe_input    
{
  margin: 0px;
}

.footer a {
    padding: 10px 0px;
    color: #fff;
    font-size: 13px !important;
}

.accordion-header {
    margin-bottom: 0px !important;
}

.contact_forms {
    padding-right: 30px !important;
    padding-left: 20px !important;
}


/*  */

/* .subscribe_container {
    padding: 30px;
    text-align: center;
}

.subscribe_para {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}




.subscribe_input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}


.subscribe_button {
    background-color: #0F172A;
    color: white;
    padding: 13px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: inline-block;
    margin-left: 0
}

.subscribe_button:hover {
    background-color: #333;
}


.sub_button {
padding-right: 12px;
} */


/*  */

.subscribe_container {
    padding: 30px;
    text-align: center;
}

.subscribe_para {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.row.banner {
    display: flex;
    justify-content: center; 
    align-items: center;    
    gap: 0;                 
}

.subscribe_input {
    flex: 1; 
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 0;
}

.subscribe_button {
    background-color: #0F172A;
    color: white;
    padding: 13px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin: 0; 
}

.subscribe_button:hover {
    background-color: #333;
}

.sub_button {
    padding: 0; 
}

.inputpadding {
    padding-left: 100px !important;
}


.subscribe_container .error-message {
    background: #fff;
    border-radius: 4px;
    bottom: 0;
    font-size: 12px;
    padding: 10px;
    position: absolute;
    top: 50px;
    line-height: 2px;
}