@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');





.vendor_login_heading {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    align-items: center;
}


.vendor-modal .modal-header {
    display: flex;
    justify-content: center;  
    align-items: center;      
    padding: 15px;            
    border-bottom: 1px solid #e5e5e5;

}

.vendor-modal .vendor_login_heading {
    font-family: 'Inter', sans-serif;
    font-size: 16px !important;
    font-weight: 700;
    text-align: center;
    margin: 0;  
}

.vendor-modal .form-group {
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
    margin-bottom: 15px; 
}

/* .vendor-modal .form-group label {
    font-weight: 600;
    margin-bottom: 5px; 
    text-align: left; 
    font-family: 'Inter';
    font-size: 16px;
    text-transform: none;
} */

/* .vendor-modal .form-group input {
    width: 100%; 
  
    padding: 5px;
   
    margin-top: 0px;
    font-family: 'Inter';
    font-size: 14px;
} */

.close_button {
    font-family: 'Inter' !important;
    font-size: 15px !important;
}

.modal-footer {
    display: flex;
    justify-content: center; 
    gap: 10px; 
}

.close_button, .ven_login {
    margin: 0; 
    font-family: 'Inter';
    font-size: 15px !important;
    background-color: #f44336; 
    color: #ffff;
}

/* .register-text {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    text-align: center;
    justify-content: center;
} */

.register-text {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;  
    align-items: center;
    text-align: center;
    justify-content: center;
}

.register-link {
    color: #007bff;  
    text-decoration: none; 
}

.register-link:hover {
    text-decoration: underline;  
}




.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}
.modal {
    overflow: hidden;
}


body.modal-open {
    overflow: hidden;
}
