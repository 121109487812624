
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.home_page_text {
    font-size: 22px;
    font-family: 'Inter';
}


.home_page_text {
    font-size: 15px;
    font-family: 'Inter', sans-serif;
    position: relative; 
}


.home_page_text:hover {
    text-decoration: underline !important;
}



/*  */

.paddingzero {
    padding: 0;
}


.main-menu ul {
    display: flex;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
}

.main-menu ul li {
    margin-right: 20px; 
}


.vendor-links {
    display: flex;
    justify-content: flex-end; 
    padding: 0;
    margin: 0;
    list-style: none;
    padding-top: 31px;
}

.vendor-links li {
    margin-left: 20px; 
}

.vendor-link {
    font-size: 15px;
    text-decoration: none;
    color: #3571a5;
    font-family: 'Inter';
    font-weight: 500;
}


.vendor-link:hover {
    color: #2c6597;
    text-decoration: underline !important;
}

@media (max-width: 767px) {
    .menu-links {
        justify-content: space-between; 
    }

    .vendor-links {
        justify-content: flex-start; 
        gap: 15px;
    }
}

/*  */

.icon_header_text {
    font-family: 'Inter';
    font-size: 16px;
}


.navbar-left_header li {
    display: inline-block; 
}
.navbar-left_header li:first-child {
    margin-right: 10px; 
}
.header_links {
    font-family: 'Inter';
    font-size: 16px;
}


.accordion-header .accordion-button
{
border-radius: 4px 4px 0px 0px;
}
.accordion-body p
{
    padding: 20px;
}