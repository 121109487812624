@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');



.aboutus_heading {
    font-family: 'Inter';
    font-size: 18px;
}

.aboutus_para {
    font-family: 'Inter';
    font-size: 14px;
}