@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


.contactus_heading {
    font-family: 'Inter';
    font-size: 16px
}

.contactus_button {
    background-color: rgb(143, 45, 45);
    color: white;
    
}

.contact-form {
    /* background-color: rgb(112, 151, 184); */
    margin-top: 30px;
    margin-bottom: 30px;
}

/*  */

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
    font-family: 'Inter';
}

.icons_contact_page {
    font-size: 20px;
    font-family: 'Inter';
    font-weight: 700;
}

.icons_contact_page_text {
    font-size: 12px;
}


/*  */


.contact-us-details {
    padding: 0;
    margin: 0;
    list-style: none;
}

.contact-us-details li {
    display: flex;
    align-items: center;
    margin-bottom: 10px; 
}

.left_alignment {
    margin-right: 10px; 
    display: flex;
    align-items: center; 
    font-size: 15px;
}

.media-body {
    flex: 1; 
}

.icons_contact_page {
    margin: 0;
    font-size: 18px; 
}

.icons_contact_page_text {
    margin: 0;
    font-size: 14px; 
    word-wrap: break-word; 
    white-space: normal;
    font-family: 'Inter';
    overflow-wrap: break-word;
}


@media (max-width: 991px) {
    .contact-us-details li {
        display: block; 
        margin-bottom: 15px;
    }

    .media-left {
        display: block; 
        margin-bottom: 5px;
    }

    .media-body {
        text-align: left; 
    }
}

.contact_row{
    margin-top: 50px;
}

.contact_row {
    margin-top: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
}

.contact_buttons {
    font-family: 'Inter';
    background-color:  #0F172A;
}

.contacts {
    font-family: 'Inter';
    color: #ffffff;
}

/*  */

.field-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    margin-bottom: 15px; 
}


.field_title {
    margin-bottom: 5px;
    text-align: left;
    font-weight: bold;
    display: block;
    width: 100%; 
    font-family: 'Inter';
    font-size: 14px;
}


.form_input {
    flex: 1; 
}


.form_input input,
.form_input textarea {
    width: 100%; 
    padding: 10px;
    margin-top: 5px;
}


.invalid-feedback {
    color: red;
    font-size: 13px;
}


.row-two-inputs {
    display: flex;
    justify-content: space-between;
    gap: 20px; 
}

.row-two-inputs .form_input {
    flex: 0 0 48%; 
}

.section-title {
    font-family: 'Inter';
}